<template>
  <b-container>
    <validation-observer ref="updateTraceability">
      <b-form
        @submit.prevent="handleSubmit"
        @keydown.enter="handleSubmit"
      >
        <b-row class="pt-2">
          <b-col sm="6">
            <validation-provider
                #default="{ errors }"
                :name="'Máquina'"
                rules="required"
            >
              <b-form-group :label="'Máquina'" label-for="maquina-trace">
                <v-select
                  v-model="maquina"
                  label="name"
                  :filterable="false"
                  :searchable="true"
                  :options="selectMachines"
                  :placeholder="'Máquina'"
                >
                </v-select>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col
            sm="6"
          >
            <b-form-group
              label="Orden de fabricación"
              label-for="trace-of"
            >
              <validation-provider
                #default="{ errors }"
                name="Orden de fabricación"
              >
                <b-form-input
                  id="trace-of"
                  v-model="of_edit"
                  name="trace-of"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
                label="Cantidad"
                label-for="trace-cantidad"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Cantidad"
              >
                <b-form-input
                    id="trace-cantidad"
                    v-model="cantidad_edit"
                    name="trace-cantidad"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
                label="Perfil"
                label-for="trace-perfil"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Perfil"
              >
                <b-form-input
                    id="trace-perfil"
                    v-model="perfil_edit"
                    name="trace-perfil"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
                label="Colada"
                label-for="trace-colada"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Colada"
              >
                <b-form-input
                    id="trace-colada"
                    v-model="colada_edit"
                    name="trace-colada"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
                label="Marca"
                label-for="trace-marca"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Marca"
              >
                <b-form-input
                    id="trace-marca"
                    v-model="marca_edit"
                    name="trace-marca"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              class="text-right"
          >
            <b-button
                type="submit"
                variant="primary"
                class="mt-2 mr-1"
            >
              {{ "Enviar" }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-container>
</template>

<script>
import { BRow, BCol, BButton, BFormGroup, BFormInput, BContainer, BForm, } from "bootstrap-vue";
import { mapActions, mapGetters, mapMutations } from 'vuex'
import vSelect from "vue-select";

export default {
  props: {
    traceability: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    BContainer,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BCol,
    BRow,
    vSelect,
  },
  data() {
    return {
      colada_edit: '',
      machine_edit: '',
      of_edit: '',
      cantidad_edit: '',
      perfil_edit: '',
      marca_edit: '',
      maquina: {},
    }
  },
  watch: {

  },
  computed: {
    ...mapGetters({
      selectMachines: 'machines/getSelectMachines',
    }),
  },
  methods: {
    ...mapActions({
      getMachines: 'machines/getSelectMachines',
      edit: 'traceabilities/edit',
    }),
    chargeData() {
      if (this.traceability) {
        this.of_edit = this.traceability.of
        this.colada_edit = this.traceability.colada
        this.perfil_edit = this.traceability.perfil
        this.marca_edit = this.traceability.marca
        this.cantidad_edit = this.traceability.cantidad
        this.maquina = this.traceability.machine
      }
    },
    handleSubmit() {
      this.$refs.updateTraceability.validate().then(success => {
        if (success) {
          const formData = this.createFormData()
          if (formData) {
            const idTrace = this.traceability.id
            this.edit({ id: idTrace, traceability: formData })
          }
        }
      })
    },
    createFormData() {
      const data = new FormData()
      data.append('colada', this.colada_edit)
      data.append('of', this.of_edit)
      data.append('perfil', this.perfil_edit)
      data.append('marca', this.marca_edit)
      data.append('cantidad', this.cantidad_edit)
      data.append('machine_id', this.maquina.id)

      return data
    },
  },
  async created() {
    await this.chargeData()
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
