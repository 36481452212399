<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <div
            class="d-flex justify-content-between align-items-center px-1 py-50"
          >
            <div class="col-6">
              <h3 class="m-0">
                {{ "Trazabilidad" }}
              </h3>
            </div>
            <div class="d-flex align-items-center">
              <b-input-group class="input-group">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchTerm"
                  type="text"
                  class="d-inline-block"
                  :placeholder="'Búsqueda'"
                  @keydown.enter.native="handleSearch"
                />
                <b-button
                  variant="primary"
                  size="sm"
                  class="mr-1 ml-1"
                  @click="handleSearch"
                >
                  {{ "Buscar" }}
                </b-button>
              </b-input-group>
              <span
                class="ml-1 cursor-pointer"
                @click="visibleFilter = !visibleFilter"
              >
                <feather-icon
                  icon="FilterIcon"
                  size="20"
                />
              </span>
            </div>
          </div>
          <div>
            <b-collapse
              id="collapse"
              v-model="visibleFilter"
              class="mt-2"
            >
              <div class="px-2">
                <b-row>
                  <b-col sm="4">
                    <b-form-group
                      :label="'Fecha inicio'"
                      label-for="filter-fechaini"
                    >
                      <flat-pickr
                        v-model="date_ini"
                        name="fecha_ini"
                        class="form-control"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      :label="'Fecha fin'"
                      label-for="filter-fechafin"
                    >
                      <flat-pickr
                        v-model="date_fin"
                        name="fecha_ini"
                        class="form-control"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      :label="'Obra'"
                      label-for="filter-project"
                    >
                      <v-select
                        v-model="project"
                        label="name"
                        :filterable="true"
                        :searchable="true"
                        :options="selectProjects"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                        :label="'Máquina'"
                        label-for="filter-maquina"
                    >
                      <v-select
                          v-model="machine"
                          label="name"
                          :filterable="true"
                          :searchable="true"
                          :options="selectMachines"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="4" class="mt-2">
                    <b-button
                        variant="primary"
                        size="sm"
                        class="mr-1 text-nowrap"
                        @click="urlExcelMachine('excel')"
                    >
                      Descargar lista Excel
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-collapse>
          </div>
          <b-table
            hover
            responsive
            :items="items"
            :fields="columns"
          >
            <template #cell(task.day)="data">
              <span v-if="data.item.task">
                {{ dateColumn(data.item.task.day) }}
              </span>
            </template>
            <template #cell(task)="data">
              <span v-if="data.item.task">
                <b-link
                    :to="{ name: 'viewTask', params: { id: data.item.task.id } }"
                    class="link"

                ><div v-html="data.item.task.description" ></div></b-link
                >
              </span>
            </template>
            <template #cell(machine)="data">
              <span v-if="data.item.machine">
                {{data.item.machine.name}}
              </span>
              <span v-else-if="data.item.maquina">
                {{data.item.maquina}}
              </span>
            </template>
            <template #cell(client_object)="data">
              <b-link
                  v-if="data.item.client_object"
                  :to="{
                  name: 'viewClient',
                  params: { id: data.item.client_object.id },
                }"
                class="link"
              >
                <span>{{ data.item.client_object.name }}</span>
              </b-link>
            </template>
            <template #cell(obra)="data">
              <p class="m-0" v-if="data.item.obra">
                <b-link
                  :to="{
                    name: 'viewProject',
                    params: { id: data.item.obra.id },
                  }"
                  class="link"
                >
                  <span>{{ data.item.obra.name }}</span>
                </b-link>
              </p>
              <span v-if="data.item.obra && data.item.obra.SKU" class="font-small">{{
                data.item.obra.SKU
                }}</span>
            </template>

            <template #cell(user)="data">
              <b-avatar
                v-if="data.item.user && data.item.user.avatar_url"
                size="md"
                :src="data.item.user.avatar_url"
              />
              <b-avatar v-else size="md" />
              <span class="ml-1">
                <b-link
                  v-if="data.item.user"
                  :to="{ name: 'viewUser', params: { id: data.item.user.id } }"
                  class="link"
                >
                  <span>{{ data.item.user.name }}</span>
                </b-link>
              </span>
            </template>
            <template #cell(actions)="data">
              <span>
                <a
                  class="d-inline-block px-50 text-dark"
                  @click="showModal(data.item)"
                >
                  <feather-icon icon="Edit2Icon" />
                </a>
                <span
                  class="d-inline-block px-50 text-danger"
                  @click="deleteTrazabilidad(data.item.id)"
                >
                  <feather-icon icon="TrashIcon" />
                </span>
              </span>
            </template>
          </b-table>
          <div
            class="d-flex align-items-center justify-content-between mb-1 px-2"
          >
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap"> {{ "Por página" }}: </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="ml-50 mr-1"
                @input="handlePageChange"
              />
              <span class="text-nowrap"> {{ "Total" }}: {{ totalItems }}</span>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      v-model="modalShow"
      :title="'Editar trazabilidad'"
      size="lg"
      hide-footer
    >
      <form-edit-trace :traceability="edit" />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  BPagination,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
  BCard,
  BTable,
  BCol,
  BRow,
  BLink,
  BBadge,
  BCollapse,
  BFormGroup,
  BAvatar,
} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import store from '@/store/index'
import { FlatPickrToTimestamp, TimestampToFlatPickr } from '@/libs/helpers'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { config } from '@/shared/app.config'
import axios from 'axios'
import FormEditTrace from '@/views/traceabilities/FormEditTrace.vue'

export default {
  components: {
    FormEditTrace,
    BCard,
    BTable,
    BButton,
    BPagination,
    BCol,
    BRow,
    BLink,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
    BBadge,
    vSelect,
    BCollapse,
    BFormGroup,
    flatPickr,
    BAvatar,
  },
  data() {
    return {
      edit: {},
      download: true,
      log: [],
      currentPage: 1,
      pageLength: 10,
      dir: false,
      pages: ['10', '15', '25'],
      columns: [
        {
          label: 'Fecha',
          key: 'task.day',
          sortable: true,
        },
        {
          label: 'Obra',
          key: 'obra',
          sortable: true,
          thStyle: { minWidth: "15rem" },
        },
        {
          label: 'Tarea',
          key: 'task',
          thStyle: { minWidth: "15rem" },
        },
        {
          label: 'Of',
          key: 'of',
        },
        {
          label: 'Cantidad',
          key: 'cantidad',
        },
        {
          label: 'Perfil',
          key: 'perfil',
        },
        {
          label: 'Nº Colada',
          key: 'colada',
        },
        {
          label: 'Marca',
          key: 'marca',
        },
        {
          label: 'Máquina',
          key: 'machine',
          sortable: true,
        },
        {
          label: 'Operario',
          key: 'user',
          thStyle: { minWidth: "13rem" },
        },
        {
          label: 'Acciones',
          key: 'actions',
          class: 'column-actions',
        },
      ],
      searchTerm: '',
      date_ini: '',
      date_fin: '',
      project: '',
      machine: '',
      visibleFilter: false,
      modalShow: false,
    }
  },
  computed: {
    ...mapGetters({
      items: 'traceabilities/getItems',
      totalItems: 'traceabilities/getTotalItems',
      selectProjects: 'projects/getSelectProjects',
      selectMachines: 'machines/getSelectMachines',
      chargeList: 'traceabilities/getChargeList',
    }),
    statusVariant() {
      const statusColor = {
        1: 'light-success',
        0: 'light-danger',
      }
      return status => statusColor[status]
    },
    statusVariantText() {
      const statusText = {
        1: 'Activo',
        0: 'Desactivado',
      }
      return status => statusText[status]
    },
    projectProp() {
      if (this.project) {
        return this.project.id
      }
      return ''
    },
  },
  watch: {
    chargeList() {
      if (this.chargeList === 1) {
        this.chargeData()
        this.modalShow = false
      }
    },
    sortBy() {
      this.currentPage = 1
      this.chargeData()
    },
    sortDesc() {
      this.currentPage = 1
      this.chargeData()
    },
    project() {
      this.currentPage = 1
      this.chargeData()
    },
    machine() {
      this.currentPage = 1
      this.chargeData()
    },
    date_ini() {
      this.chargeData()
    },
    date_fin() {
      this.chargeData()
    },
    orderby() {
      this.currentPage = 1
      this.chargeData()
    },
  },
  created() {
    this.chargeData()
    this.chargeProjects()
    this.chargeMachines()
  },
  methods: {
    ...mapActions({
      list: 'traceabilities/getListTraceabilities',
      delete: 'traceabilities/delete',
      getSelectProjects: 'projects/getSelectProjects',
      getSelectMachines: 'machines/getSelectMachines',
    }),
    ...mapMutations({
      loading: 'app/loading',
      setChargeList: 'traceabilities/setChargeList',
    }),
    showModal(item) {
      this.edit = item
      this.modalShow = true
    },
    urlExcelMachine() {
      this.loading(true)
      const filterDateFin = this.date_fin ? FlatPickrToTimestamp(this.date_fin) : ''
      const filterDateIni = this.date_ini ? FlatPickrToTimestamp(this.date_ini) : ''
      const project = this.project ? this.project.id : ''
      const machine = this.machine ? this.machine.id : ''

      axios({
        url: `${config.webApiBase}/machines/list/download`,
        data: {
          project_id: project,
          date_ini: filterDateIni,
          date_fin: filterDateFin,
          machine_id: machine,
        },
        method: 'POST',
        responseType: 'blob', // important
      }).then(response => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data)

        // create "a" HTML element with href to file & click
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', 'list.xlsx')

        document.body.appendChild(link)
        link.click()

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link)
        URL.revokeObjectURL(href)
        this.loading(false)
      })
    },
    chargeProjects() {
      let client = ''
      if (this.client) {
        client = this.client.id
      }
      this.getSelectProjects({ client })
    },
    chargeMachines() {
      this.getSelectMachines()
    },
    dateColumn(time) {
      return TimestampToFlatPickr(time)
    },
    chargeData() {
      const filterDateFin = this.date_fin ? FlatPickrToTimestamp(this.date_fin) : ''
      const filterDateIni = this.date_ini ? FlatPickrToTimestamp(this.date_ini) : ''
      const project = this.project ? this.project.id : ''
      const machine = this.machine ? this.machine.id : ''
      this.list({
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        date_ini: filterDateIni,
        date_fin: filterDateFin,
        project,
        machine,
      })
      this.setChargeList(0)
    },
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
    onSortChange() {
      this.currentPage = 1
      this.chargeData()
    },
    deleteTrazabilidad(id) {
      this.$bvModal
        .msgBoxConfirm(`¿Estás seguro de eliminar esta trazabilidad?`, {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: 'Eliminar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.delete(id)
          }
        })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.table th, .table td{
  padding: 0.72rem 1.3rem !important;
}
</style>
