var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('validation-observer',{ref:"updateTraceability"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',{staticClass:"pt-2"},[_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Máquina',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Máquina',"label-for":"maquina-trace"}},[_c('v-select',{attrs:{"label":"name","filterable":false,"searchable":true,"options":_vm.selectMachines,"placeholder":'Máquina'},model:{value:(_vm.maquina),callback:function ($$v) {_vm.maquina=$$v},expression:"maquina"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Orden de fabricación","label-for":"trace-of"}},[_c('validation-provider',{attrs:{"name":"Orden de fabricación"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"trace-of","name":"trace-of"},model:{value:(_vm.of_edit),callback:function ($$v) {_vm.of_edit=$$v},expression:"of_edit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Cantidad","label-for":"trace-cantidad"}},[_c('validation-provider',{attrs:{"name":"Cantidad"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"trace-cantidad","name":"trace-cantidad"},model:{value:(_vm.cantidad_edit),callback:function ($$v) {_vm.cantidad_edit=$$v},expression:"cantidad_edit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Perfil","label-for":"trace-perfil"}},[_c('validation-provider',{attrs:{"name":"Perfil"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"trace-perfil","name":"trace-perfil"},model:{value:(_vm.perfil_edit),callback:function ($$v) {_vm.perfil_edit=$$v},expression:"perfil_edit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Colada","label-for":"trace-colada"}},[_c('validation-provider',{attrs:{"name":"Colada"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"trace-colada","name":"trace-colada"},model:{value:(_vm.colada_edit),callback:function ($$v) {_vm.colada_edit=$$v},expression:"colada_edit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Marca","label-for":"trace-marca"}},[_c('validation-provider',{attrs:{"name":"Marca"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"trace-marca","name":"trace-marca"},model:{value:(_vm.marca_edit),callback:function ($$v) {_vm.marca_edit=$$v},expression:"marca_edit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s("Enviar")+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }